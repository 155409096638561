import featureNames from 'constants/feature_names'

angular.module 'gym.components'
  .controller 'AddEnrolmentLimitSequenceConditionCtrl', (
    $scope,
    $modalInstance,
  ) ->
    $scope.featureNames = featureNames
    $scope.intervalUnits =
      [
        ['days', 'day(s)']
        ['weeks', 'week(s)']
        ['months', 'month(s)']
      ]

    $scope.params =
      enable_limit_count: false
      enable_time_period: false
      limit_term_unit: 'days'

    $scope.toggleTimePeriod = (val) ->
      $scope.params.enable_time_period = val
      $scope.params.limit_to_current_month = false

    $scope.onLimitToCurrentMonthToggle = ->
      if $scope.params.limit_to_current_month
        $scope.params.enable_time_period = false

    $scope.submit = ->
      if $scope.params.enable_limit_count && !($scope.params.limit_count >= 1)
        $scope.errors =
          limit_count: ['Should be greater than or equal to 1']
        return

      if $scope.params.enable_time_period && !($scope.params.limit_term_number >= 1)
        $scope.errors =
          limit_term_number: ['Should be greater than or equal to 1']
        return

      $modalInstance.close($scope.params)

    $scope.cancel = ->
      $modalInstance.dismiss('cancel')
