angular
  .module 'gym.components'
  .directive 'ezidebitCreditCardForm', ->
    restrict: 'EA'
    scope:
      gym: '='
      contact: '='
      customer: '='
      updateThisContactOnly: '='
      paymentAccount: '='
      closeModal: '&'
      isCustomerExist: '&'
      onCreate: '&'
      onUpdate: '&'
    template: require('templates/components/contacts/wallet/ezidebit_credit_card_form.html.slim')
    controller: (
      $scope,
      $timeout,
      CreditCardValidationService,
      CreditCardExpirationDatesService,
      EzidebitService
    ) ->
      publicKey = $scope.paymentAccount.public_key
      endpoint = $scope.paymentAccount.payment_system_url

      $scope.card = card = {
        firstName: $scope.contact.contact_information.first_name
        lastName: $scope.contact.contact_information.last_name
      }

      $scope.years = CreditCardExpirationDatesService.getYears()
      $scope.months = CreditCardExpirationDatesService.getMonths()
      $scope.customerReference = EzidebitService.getCustomerReference({gymId: $scope.gym.id, contactId: $scope.contact.id, context: 'CP'})

      initChangePaymentDetails = ->
        $timeout ->
          window.initEziDebit()
          eziDebit.init publicKey, {
            submitAction: 'ChangeCustomerPaymentInfo'
            submitButton: 'submit-ezidebit-credit-card-form'
            submitCallback: onUpdate
            submitError: onError
            customerRef: 'customerRef'
            nameOnCard: 'nameOnCard'
            cardNumber: 'cardNumber'
            cardExpiryMonth: 'expiryMonth'
            cardExpiryYear: 'expiryYear'
          }, endpoint

      initCreateCustomer = ->
        $timeout ->
          params =
            submitAction: 'SaveCustomer'
            submitButton: 'submit-ezidebit-credit-card-form'
            submitCallback: onCreate
            submitError: onError
            customerFirstName: 'firstName'
            customerLastName: 'lastName'
            nameOnCard: 'nameOnCard'
            cardNumber: 'cardNumber'
            cardExpiryMonth: 'expiryMonth'
            cardExpiryYear: 'expiryYear'
            customerReference: 'customerReference'

          if $scope.customer.clean_contact_email
            params.customerEmail = 'customerEmail'
          if $scope.customer.clean_contact_mobile_number
            params.customerMobile = 'customerMobile'

          window.initEziDebit()
          eziDebit.init publicKey, params, endpoint

      if $scope.isCustomerExist()
        initChangePaymentDetails()
      else
        initCreateCustomer()

      $scope.checkCardNumber = ->
        card.number = card.number?.replace(/[^0-9]/g, '')
        $scope.creditCardValid = CreditCardValidationService.check(card.number)

      $scope.nameValid = (name) ->
        CreditCardValidationService.nameValid(name)

      $scope.isFormValid = ->
        $scope.card.form.$valid && $scope.creditCardValid &&
          $scope.nameValid($scope.card.cardholderName)

      $scope.$watch 'isFormValid()', (value) ->
        $scope.$emit 'contact:paymentMethod:formStatusChanged', value

      onSuccess = (response) ->
        card.error_message = undefined
        enableForm()
        getWalletDetails(response.Data)

      onUpdate = (response) ->
        $timeout ->
          customer = onSuccess(response)
          $scope.onUpdate(customer: customer)

      onCreate = (response) ->
        $timeout ->
          customer = onSuccess(response)
          $scope.onCreate(customer: customer)

      onError = (error) ->
        $timeout ->
          enableForm()
          card.error_message = error

      getWalletDetails = (customer) ->
        length = card.number.length
        wallet =
          customer_ref: customer.CustomerRef || $scope.customer.customer_ref
          last4: card.number.substr length-4
          full_name: card.cardholderName
          exp_month: card.expirationMonth
          exp_year: card.expirationYear
          rootObjectName: 'payment_method'
          payment_method: 'credit_card'
        wallet.bsb = ''
        wallet

      enableForm = ->
        $scope.isFormDisabled = false
