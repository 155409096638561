const SEQUENCE = 'Automation';
const SEQUENCE_LOWERCASE = 'automation';
const SEQUENCE_PLURAL = 'Automations';
const SEQUENCE_PLURAL_LOWERCASE = 'automations';

const FeatureNames = {
  SEQUENCE,
  SEQUENCE_LOWERCASE,
  SEQUENCE_PLURAL,
  SEQUENCE_PLURAL_LOWERCASE,
};

export default FeatureNames;
