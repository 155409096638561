import featureNames from 'constants/feature_names'

angular.module 'shared'
  .controller 'ShowFlagsModalCtrl', (
    $scope,
    $stateParams,
    $window,
    $modalInstance,
    $http,
    contactType,
    contactId,
    permissions,
    dismissEndpoint,
    flags,
    addFlag
  ) ->
    gymId = $stateParams.gymId
    $scope.permissions = permissions
    $scope.featureNames = featureNames
    $scope.canManage = 'manage_contact_flags' in permissions
    baseUrl = "/gyms/#{gymId}/#{contactType}/#{contactId}/flags"

    $scope.flags = flags

    $scope.getTags = (flag) ->
      flag.tag_list.join(', ')

    $scope.dismiss = (flag, index) ->
      message = "Are you sure?"
      if $window.confirm message
        url = "#{baseUrl}/#{flag.id}/#{dismissEndpoint}"
        $http.delete(url).then () ->
          $scope.flags.splice(index, 1)

    $scope.cancel = ->
      $modalInstance.dismiss('cancel')

    $scope.canAddFlag =
      $scope.canManage && addFlag

    $scope.addFlag = () ->
      $scope.cancel()
      addFlag()
