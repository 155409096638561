import days from 'constants/days'
import hours, { hoursMap } from 'constants/hours'
import featureNames from 'constants/feature_names'

angular
  .module 'gym.components'
  .directive 'sequenceStepCustomHours', ->
    restrict: 'EA'
    template: require('templates/components/sequences/sequence_step_custom_hours.html.slim')
    scope:
      condition: '='
      sequence: '='
    controller: ($rootScope, $scope, $filter) ->
      gym = $rootScope.gym
      gymSequenceDetail = gym.gym_sequence_detail

      $scope.days = days.map (day) ->
        id: day.value
        name: $filter('humanize')(day.label)

      $scope.hours = hours.map (hour) ->
        id: hour.value
        name: hour.label

      $scope.hoursUnitSelected = ->
        ($scope.condition.time_since_previous_step_unit is 'hours' || $scope.condition.trigger_time_unit is 'hours') &&
          !$scope.condition.immediate

      $scope.isTooltipVisible = ->
        hasRestrictedHours = gymSequenceDetail &&
          (gymSequenceDetail.no_comms_payloads_before_hour || gymSequenceDetail.no_comms_payloads_after_hour)
        hasRestrictedHours && !$scope.hoursUnitSelected()

      getTooltipContent = ->
        return unless $scope.isTooltipVisible()

        channels = if gym.mobile_app_enabled
          'Emails, SMSes and Push Notifications'
        else
          'Emails and SMSes'

        hoursDesc =
          if gymSequenceDetail.no_comms_payloads_before_hour && gymSequenceDetail.no_comms_payloads_after_hour
            "#{channels} will only be sent during the hours of #{hoursMap[gymSequenceDetail.no_comms_payloads_before_hour].label} - #{hoursMap[gymSequenceDetail.no_comms_payloads_after_hour].label}."
          else if gymSequenceDetail.no_comms_payloads_before_hour
            "#{channels} will not be sent before #{hoursMap[gymSequenceDetail.no_comms_payloads_before_hour].label}."
          else if gymSequenceDetail.no_comms_payloads_after_hour
            "#{channels} will not be sent after #{hoursMap[gymSequenceDetail.no_comms_payloads_after_hour].label}."

        """
          #{hoursDesc}
          <br/>
          All other #{featureNames.SEQUENCE_LOWERCASE} actions can happen at any time, unless restricted here.
        """

      $scope.tooltipContent = getTooltipContent()
