angular
  .module 'shared'
  .controller 'RemainingCharactersDetailsModal', (
    $scope,
    $modalInstance,
  ) ->
    $scope.isAccountOwner = window.currentUser.account_owner
    
    $scope.close = ->
      $modalInstance.dismiss('cancel')
