angular.module 'website'
  .controller 'website.calendarCtrl', (
    $scope,
    $stateParams,
    $state,
    $modal,
    $filter,
    $compile,
    $timeout,
    $window,
    $location,
    $document,
    Restangular,
    DataService,
    CalendarService,
    ContrastColor
  ) ->
    domain = DataService.domain
    $scope.instructors = DataService.instructors
    $scope.eventTypes = DataService.event_types
    $scope.glossary = DataService.glossary
    $scope.filtersMultiSelect = DataService.gym_config.website_calendar_filters is 'multi_select'
    $scope.showInstructor = DataService.gym_config.show_instructor_on_website_calendar
    $scope.showCapacity = DataService.gym_config.show_capacity_on_website_calendar
    $scope.bookingSuccessGtmId = DataService.gym_config.gtm_code_for_website_booking_success
    $scope.freeTrialBookingSuccessful = false
    $scope.pixelId = DataService.gym_config.facebook_pixel_id_for_free_trial_calendar
    $scope.pixelEvent = DataService.gym_config.facebook_pixel_event_for_free_trial_calendar

    defaultView =
      switch DataService.gym_config.external_calendar_default_view
        when 'week' then 'agendaWeek'
        when 'daily_agenda' then 'basicDay'
        else 'month'

    Website = Restangular.all('websites').all(domain)
    Classrooms = Website.all('classrooms')
    CalendarData = Website.all('calendar').all('data')

    parseIds = (val) ->
      return [] unless val
      if Array.isArray val
        val.map (item) -> +item
      else
        [+val]

    $scope.onBookingSuccess = (eventParams) ->
      $scope.bookingComplete = true
      $timeout () ->
        return unless window.dataLayer
        window.dataLayer.push(eventParams)
      , 500

    onEventSelect = (event) ->
      calendar = $('#calendar')
      $document.scrollTop(0)
      bookingModal = $modal.open
        template: require('templates/create_booking_modal.html')
        controller: 'BookingModalCtrl'
        resolve:
          event: -> event
          isFreeTrials: -> false
          onBookingSuccess: -> $scope.onBookingSuccess

      bookingModal.result.then ->
        if event.free_class == true
          $scope.freeTrialBookingSuccessful = true

        calendar.fullCalendar('refetchEvents')

    $scope.locations = []
    $scope.selectedInstructors = []
    $scope.selectedInstructors = $scope.instructors.filter (type) ->
      id = type.id.toString()
      ids = $stateParams['instructor_id[]']
      id is $stateParams.instructor_id || (ids && id in ids)
    $scope.selectedEventTypes = $scope.eventTypes.filter (type) ->
      id = type.id.toString()
      ids = $stateParams['event_type_id[]']
      id is $stateParams.event_type_id || (ids && id in ids)

    Classrooms.getList().then (locations) ->
      $scope.locations = locations
      $scope.locationsDidResolved = true
      defaultLocationIds = if parseIds($stateParams.location_id)?.length
        parseIds($stateParams.location_id)
      else if parseIds($stateParams['location_id[]'])?.length
        parseIds($stateParams['location_id[]'])
      else if DataService.gym_config.default_calendar_location_id
        [DataService.gym_config.default_calendar_location_id]
      else []
      $scope.selectedLocations =
        _.filter locations, (location) -> location.id in defaultLocationIds

      if parseIds($stateParams.ex_location_id)?.length
        $scope.selectedLocations =
          _.filter locations, (location) -> location.id not in parseIds($stateParams.ex_location_id)
      else if parseIds($stateParams['ex_location_id[]'])?.length
        $scope.selectedLocations =
          _.filter locations, (location) -> location.id not in parseIds($stateParams['ex_location_id[]'])

      if defaultLocationIds?.length && !$scope.selectedLocations?.length
        $scope.selectedLocations = [locations[0]]

      $scope.eventSources = [{events: (start, end, callback) ->
        startDate = moment(start).subtract(1, 'days').format('X')
        endDate = moment(end).add(1, 'days').format('X')
        params =
          'start': startDate
          'end': endDate
          'location_id[]': $scope.selectedLocations.map((item) -> item.id)
          'instructor_id[]': $scope.selectedInstructors.map((item) -> item.id)
          'event_type_id[]': $scope.selectedEventTypes.map((item) -> item.id)
          '_': new Date().getTime()
        CalendarData.getList(params).then (data) ->
          $scope.eventsLoaded = true
          items = data.plain().map (item) ->
            item.textColor = ContrastColor.get(item.color)
            item
          callback items
      }]

    $scope.onLocationsChange = (selectedLocations) ->
      $scope.selectedLocations = selectedLocations
      $location.search 'location_id', selectedLocations.map((item) -> item.id)
      $location.search 'ex_location_id', []
      $location.search 'ex_location_id[]', []
      $scope.calendarView.calendar.refetchEvents()

    $scope.onInstructorsChange = (selectedInstructors) ->
      $scope.selectedInstructors = selectedInstructors
      $scope.calendarView.calendar.refetchEvents()

    $scope.onEventTypesChange = (selectedEventTypes) ->
      $scope.selectedEventTypes = selectedEventTypes
      $location.search 'event_type_id', selectedEventTypes.map((item) -> item.id)
      $scope.calendarView.calendar.refetchEvents()

    $scope.currentView = ->
      $scope.calendarView?.calendar.getView()

    rerenderEvents = ->
      $timeout ->
        $scope.calendarView.calendar.option('aspectRatio', 1.35)
      , 50

    $scope.setDailyAgenda = ->
      $scope.calendarView.calendar.changeView('basicDay')

    $scope.showWeeklyAgenda = ->
      if $scope.isIframe()
        $scope.displayWeeklyAgenda = true
      else
        url = "/websites/#{domain}/calendar/weekly_agenda"
        $window.open url, '_self'
        true

    $scope.showFreeTrials = ->
      if $scope.isIframe()
        $scope.displayFreeTrials = true
      else
        url = "/websites/#{domain}/calendar/free_trials"
        $window.open url, '_self'
        true

    $scope.showCalendar = ->
      $scope.displayWeeklyAgenda = false
      $scope.displayFreeTrials = false

    addCustomButtons = ->
      unless angular.element('website-calendar-navigation-buttons').length
        compiledButtons = $compile('<website-calendar-navigation-buttons/>')($scope)
        angular.element('.fc-header-left').append(compiledButtons)

    $scope.isIframe = ->
      $location.path().indexOf('calendar/iframe') > -1

    $scope.calendarReady = ->
      $scope.locationsDidResolved && $scope.uiConfig

    CalendarService.getEvent($stateParams.event_id).then (event) ->
      if event
        initial = moment(event.start, 'YYYY/MM/DD HH:mm')
        $scope.initialYear = +initial.format('YYYY')
        $scope.initialMonth = initial.format('M') - 1
        $scope.initialDay = +initial.format('D')
        onEventSelect(event)

      $scope.uiConfig =
        calendar:
          year: $scope.initialYear,
          month: $scope.initialMonth,
          date: $scope.initialDay,
          editable: false
          allDayDefault: false
          allDaySlot: false
          defaultView: defaultView
          firstHour: 9
          firstDay: DataService.calendar_start_wday
          minTime: DataService.gym_config.calendar_display_hours_override?[0] || 0
          maxTime: DataService.gym_config.calendar_display_hours_override?[1] || 24
          eventClick: (event) ->
            onEventSelect(event)
          eventAfterAllRender: (view) ->
            $scope.calendarView = view
            window.calendarView = view
            addCustomButtons()

            $scope.displayOldEventsHiddenWarning =
              window.DATA?.old_event_display_threshold > moment(view.start).format('X')

            $scope.oldEventDisplayThresholdDate =
              moment(new Date(window.DATA.old_event_display_threshold * 1000)).format('LL')

            filterElement = '''
              <calendar-filters
                locations="locations"
                instructors="instructors"
                event-types="eventTypes"
                selected-locations="selectedLocations"
                selected-instructors="selectedInstructors"
                selected-event-types="selectedEventTypes"
                multi-select="filtersMultiSelect"
                display-old-events-hidden-warning="displayOldEventsHiddenWarning"
                old-event-display-threshold-date="oldEventDisplayThresholdDate"
                on-locations-change="onLocationsChange(locations)"
                on-instructors-change="onInstructorsChange(instructors)"
                on-event-types-change="onEventTypesChange(eventTypes)"
                hide-instructor="!showInstructor"
              />
            '''

            dropdown = $compile(filterElement)($scope)
            if !$(".fc-subheader").length
              $(".fc-header").after(dropdown)
            else
              $('.fc-subheader').replaceWith(dropdown)

          dayClick: (date, allDay, event, sourceView) ->
            calendar = $('#calendar')
            calendar.fullCalendar('changeView', 'agendaDay')
            calendar.fullCalendar('gotoDate', date)
          header:
            left: 'agendaDay,agendaWeek,month'
            center: 'title'
            right: 'today prev,next'
          buttonText:
            month: 'Month',
            agendaDay: 'Day'
            agendaWeek: 'Week'
          columnFormat:
            month: 'ddd',
            week: 'ddd d-MMM',
            day: 'dddd MMM d'

          viewRender: (view) ->
            if view.name == 'basicDay'
              rerenderEvents()

          eventRender: (event, element, view) ->
            if view.name == 'basicDay'
              eventContainer = $(element).find('.fc-event-inner')
              titleClassname = if $scope.showCapacity
                'fc-event-title'
              else
                'fc-event-title fc-event-title--without-capacity'

              typeInstructorClassname =
                if $scope.showInstructor then '' else 'event-type--without-instructor'

              typeEmptyClassname =
                if event.event_type_names then '' else 'event-type--empty'

              typeClassname =
                "event-type #{typeInstructorClassname} #{typeEmptyClassname}"

              classroomInstructorClassname =
                if $scope.showInstructor then '' else 'event-classroom--without-instructor'

              classroomDividerClassname =
                if event.event_type_names then '' else 'event-classroom--hide-divider'

              classroomClassname =
                "event-classroom #{classroomInstructorClassname} #{classroomDividerClassname}"

              if $scope.showInstructor
                'event-classroom'
              else
                'event-classroom event-classroom--without-instructor'

              if $scope.showCapacity
                eventContainer.append('<span class="event-vacancies">' + (!event.old_event && event.vacancies_count || '-') + '</span>')
              else
                eventContainer.find('.fc-event-title').addClass('fc-event-title--without-capacity')
              if $scope.showInstructor
                eventContainer.append('<span class="fc-agenda-item event-instructor">' + event.instructor_names + '</span>')
              eventContainer.append("<span class='fc-agenda-item #{typeClassname}'>#{event.event_type_names || ''}</span>")
              eventContainer.append("<span class='fc-agenda-item #{classroomClassname}'>#{event.classroom_name}</span>")
              # TODO: Need the best solution for heading
              instructorCell = if $scope.showInstructor
                '<span class="fc-agenda-item event-instructor">Instructor</span>'
              else
                ''
              capacityCell = if $scope.showCapacity
                '<span class="event-space">Places Left</span>'
              else
                ''

              typeHeaderClassname = if $scope.showInstructor
                'event-type'
              else
                'event-type event-type--without-instructor'

              classroomHeaderClassname = if $scope.showInstructor
                'event-classroom'
              else
                'event-classroom event-classroom--without-instructor'

              eventsHeader = """
                <div class="fc-agenda-head fc-event-hori">
                  <span class="fc-event-time">Time</span>
                  <span class="#{titleClassname}">Class</span>
                  #{capacityCell}
                  #{instructorCell}
                  <span class="fc-agenda-item #{typeHeaderClassname}">Type</span>
                  <span class="fc-agenda-item #{classroomHeaderClassname}">Location</span>
                </div>
              """
              $('.fc-day-header').html(eventsHeader)
