import featureNames from 'constants/feature_names'


angular
  .module 'filters'
  .filter 'membershipPlanSequenceUpfrontCost', ($rootScope, $filter) ->
    (plan, currency) ->
      if plan.cost_upfront > 0
        date = switch plan.default_upfront_date_strategy
          when 'charge_upfront_amount_on_start_date'
            'when membership starts'
          when 'charge_upfront_amount_relative_to_start_date'
            interval = $filter('pluralize')(plan.relative_upfront_date_unit, plan.relative_upfront_date_number, true)
            direction = plan.relative_upfront_date_direction
            "#{interval} #{direction} membership starts"
          when 'charge_upfront_amount_asap'
            "charged when the #{featureNames.SEQUENCE_LOWERCASE} fires"

        symbol = currency || $rootScope.gym.currency_symbol
        amount = $filter('currency')(plan.cost_upfront, symbol)
        "#{amount}, charged #{date}"
      else
        "No initial payment"
