import featureNames from 'constants/feature_names'

angular
  .module 'gym.components'
  .directive 'membershipPlanBuilder', ->
    restrict: 'EA'
    template: require('templates/components/membership_plan/membershipPlanBuilder.html.slim')
    scope:
      newMembershipPlan: '='
      membershipPlanCategories: '='
      eventTypes: '='
      gradingStyles: '='
    controller: (
      $scope,
      $rootScope,
      $modal,
      $filter,
      $http,
      $timeout,
      MembershipIntervalUnits,
      FeatureNamesService,
      EventService,
      MembershipPlanService,
      Months
    ) ->
      $scope.gym = $rootScope.gym
      $scope.currency_symbol = $rootScope.gym.currency_symbol
      $scope.membershipPlan = $scope.newMembershipPlan
      $scope.isMartialArts = $rootScope.gym.is_martial_arts
      $scope.membershipPlan.cc_invoice_receipt_enabled =
        !!$scope.membershipPlan.cc_invoice_receipts_to
      $scope.months = [['', 'Any month']].concat(Months)
      $scope.days = [1..31]
      $scope.archiveMembershipPurchaseOptions = [
        [false, 'does']
        [true, 'does not']
      ]
      $scope.automaticRescheduleLimits = [1..10]
      $scope.memberPortalOrMobileAppEnabled = $scope.gym.mobile_app_enabled || $scope.gym.member_portal_enabled
      $scope.memberPortalLabel = FeatureNamesService.memberPortal()
      $scope.featureNames = featureNames

      unless $scope.membershipPlan.default_start_date_strategy
        $scope.membershipPlan.default_start_date_strategy = 'start_on_purchase_date'

      unless $scope.membershipPlan.member_portal_start_date_limit_unit
        $scope.membershipPlan.member_portal_start_date_limit_unit = 'days'

      unless $scope.membershipPlan.waiver_start_date_limit_unit
        $scope.membershipPlan.waiver_start_date_limit_unit = 'days'

      $scope.membershipPlan.default_upfront_date_strategy ||= 'charge_upfront_amount_on_start_date'
      $scope.membershipPlan.default_repeat_date_strategy ||= 'start_repeat_payments_one_period_after_upfront'
      $scope.membershipPlan.relative_upfront_date_number ||= $scope.membershipPlan.charge_interval_number || 1
      $scope.membershipPlan.relative_upfront_date_unit ||= $scope.membershipPlan.charge_interval_unit || 'weeks'
      $scope.membershipPlan.relative_upfront_date_direction ||= 'before'
      $scope.membershipPlan.relative_repeat_date_number ||= $scope.membershipPlan.charge_interval_number || 1
      $scope.membershipPlan.relative_repeat_date_unit ||= $scope.membershipPlan.charge_interval_unit || 'weeks'
      $scope.membershipPlan.relative_start_date_number ||= 1
      $scope.membershipPlan.relative_start_date_unit ||= 'weeks'
      $scope.membershipPlan.future_booking_term_unit ||= 'weeks'
      $scope.membershipPlan.include_archived_memberships_in_purchase_count ||= false

      $scope.membershipPlan.start_on_next_upcoming_day ||= 1
      if !$scope.membershipPlan.id && $scope.gym.gym_config.recurring_bookings_feature_enabled
        $scope.membershipPlan.allow_recurring_bookings = true

      $scope.minSpecifiedStartDate = moment($scope.gym.today).add(1, 'days').toDate()
      $scope.advancedOptionsOpen = !!$scope.membershipPlan.id

      if !$scope.membershipPlan.category
        $scope.membershipPlan.category =
          id: null

      addEmptyCategory = ->
        if !_.find($scope.membershipPlanCategories, (category) -> !category.id)
          $scope.membershipPlanCategories.unshift({id: null, label: 'No category'})
      addEmptyCategory()

      $scope.toggleAdvancedOptions = () ->
        $scope.advancedOptionsOpen = !$scope.advancedOptionsOpen

      $scope.isTypeSelected = (id) ->
        id in $scope.membershipPlan.event_type_ids

      $scope.isStyleSelected = (id) ->
        id in $scope.membershipPlan.style_ids

      $scope.recurringBookingsDisabled = ->
        !$scope.membershipPlan.classes_unlimited && !$scope.membershipPlan.classes_interval_number

      updateDefaultRepeatDateStrategy = ->
        plan = $scope.membershipPlan
        isStartRepeatPaymentOnStartDate = plan.default_repeat_date_strategy is 'start_repeat_payments_on_membership_start_date'
        if !$scope.isUpfrontAndRecurring() && plan.default_repeat_date_strategy is 'start_repeat_payments_one_period_after_upfront'
          plan.default_repeat_date_strategy = 'start_repeat_payments_on_membership_start_date'
        if ($scope.isUpfrontAndRecurring() && !$scope.selectedDefaultRepeatDateStrategy) ||
          ($scope.membershipStartDateRepeatDateStrategyUnavailable() && isStartRepeatPaymentOnStartDate)
            plan.default_repeat_date_strategy = 'start_repeat_payments_one_period_after_upfront'

      $scope.onPlanTypeChange = ->
        if $scope.membershipPlan.plan_type is 'upfront'
          $scope.tempCostRepeat = $scope.membershipPlan.cost_repeat
          $scope.membershipPlan.cost_repeat = 0
        else
          $scope.membershipPlan.cost_repeat = $scope.tempCostRepeat
          updateDefaultRepeatDateStrategy()

      $scope.isFree = ->
        !parseFloat($scope.membershipPlan.cost_upfront) && !parseFloat($scope.membershipPlan.cost_repeat)

      $scope.isUpfront = ->
        parseFloat($scope.membershipPlan.cost_upfront) > 0 && !parseFloat($scope.membershipPlan.cost_repeat)

      $scope.isRecurring = ->
        parseFloat($scope.membershipPlan.cost_repeat) > 0 && !parseFloat($scope.membershipPlan.cost_upfront)

      $scope.isUpfrontAndRecurring = ->
        parseFloat($scope.membershipPlan.cost_upfront) > 0 && parseFloat($scope.membershipPlan.cost_repeat) > 0

      $scope.toggleType = (id) ->
        if $scope.isTypeSelected(id)
          $scope.membershipPlan.event_type_ids =
            _.without($scope.membershipPlan.event_type_ids, id)
        else
          $scope.membershipPlan.event_type_ids.push(id)

      $scope.toggleStyle = (id) ->
        if $scope.isStyleSelected(id)
          $scope.membershipPlan.style_ids =
            _.without($scope.membershipPlan.style_ids, id)
        else
          $scope.membershipPlan.style_ids.push(id)

      reloadEventTypes = ->
        EventService.eventTypesWithDeleted($scope.membershipPlan.event_type_ids).then (types) ->
          $scope.eventTypes = types

      $scope.manageTypes = ->
        roomModal = $modal.open
          template: require('templates/gyms/calendar/events/manage_types.html.slim')
          controller: 'ManageEventTypesModal'
          resolve:
            types: ->
              $scope.eventTypes

        roomModal.result.then ->
          reloadEventTypes()
        , ->
          reloadEventTypes()

      $scope.onClassesUnlimitedToggle = ->
        if $scope.membershipPlan.classes_unlimited
          $scope.membershipPlan.future_booking_term_unit ||= 'weeks'
        else
          $scope.membershipPlan.restrict_future_bookings_by_count = false
          $scope.membershipPlan.restrict_future_bookings_by_date = false
          $scope.membershipPlan.future_bookings_count_limit = null
          $scope.membershipPlan.future_booking_term_number = null
          $scope.membershipPlan.future_booking_term_unit = null
          $scope.membershipPlan.allow_recurring_bookings = false

      $scope.displayFutureBookingLimitsDescription = (event, type) ->
        event.preventDefault()
        $modal.open
          template: require('templates/components/membership_plan/future_booking_limits.html.slim')
          controller: 'FutureBookingLimitsModal'
          resolve:
            type: () -> type

      $scope.displayRecurringBookingsDescription = (event) ->
        event.preventDefault()
        EventService.displayRecurringBookingsDescription()

      $scope.displayTrialMembershipDescription = (event) ->
        event.preventDefault()
        MembershipPlanService.displayTrialMembershipDescription($scope.featureNames)

      $scope.membershipPlan.not_classes_interval =
        !($scope.membershipPlan.classes_interval_number? && $scope.membershipPlan.classes_interval_number > 0)
      $scope.$watch 'membershipPlan.not_classes_interval', (newVal) ->
        if newVal
          $scope.membershipPlan.classes_interval_number = 0
        if newVal && $scope.recurringBookingsDisabled()
          $scope.membershipPlan.allow_recurring_bookings = false

      $scope.daysWeeksMonthsYearsOptions = _.pairs MembershipIntervalUnits
      $scope.daysWeeksMonthsOptions = _.pairs _.omit(MembershipIntervalUnits, 'years')

      $scope.onSendInvoiceReceiptChanged = ->
        $scope.membershipPlan.tax_status = ''
        unless $scope.membershipPlan.send_invoice_receipts
          $scope.membershipPlan.cc_invoice_receipt_enabled = false
          $scope.membershipPlan.cc_invoice_receipts_to = ''

      $scope.onCcInvoiceReceiptToggleChanged = ->
        unless $scope.membershipPlan.cc_invoice_receipt_enabled
          $scope.membershipPlan.cc_invoice_receipts_to = ''

      $scope.onAutomaticRescheduleToggle = ->
        if $scope.membershipPlan.perform_automatic_reschedule
          $scope.membershipPlan.automatic_reschedule_days = 1
          $scope.membershipPlan.automatic_reschedule_limit = 3
        else
          $scope.membershipPlan.automatic_reschedule_days = 0
          $scope.membershipPlan.automatic_reschedule_limit = 0

      $scope.openMembershipPlanCategoryManagement = ->
        eventModal = $modal.open(
          template: require('templates/gyms/membership_plans/membershipPlanCategoryManagement.html.slim')
          controller: 'MembershipPlanCategoryManagementCtrl'
        )

        eventModal.result.then (->
          $scope.membershipPlanCategories.reload().then () -> addEmptyCategory()
        ), ->
          $scope.membershipPlanCategories.reload().then () -> addEmptyCategory()

      $scope.onSpecifiedStartDateSelect = ->
        $scope.membershipPlan.default_start_date_strategy = 'start_on_specific_date'

      $scope.onUpfrontCostChange = ->
        plan = $scope.membershipPlan
        if !$scope.isUpfrontAndRecurring() && plan.default_repeat_date_strategy is 'start_repeat_payments_one_period_after_upfront'
          plan.default_repeat_date_strategy = 'start_repeat_payments_on_membership_start_date'
        updateDefaultRepeatDateStrategy()

      $scope.onCostRepeatChange = ->
        updateDefaultRepeatDateStrategy()

      $scope.onDefaultRepeatDateStrategyChange = ->
        $scope.selectedDefaultRepeatDateStrategy = $scope.membershipPlan.default_repeat_date_strategy

      $scope.onDefaultStartDateStrategyChange = ->
        $scope.membershipPlan.specified_start_date = undefined
        $scope.membershipPlan.allow_customer_to_choose_start_date_on_member_portal = false
        $scope.membershipPlan.allow_customer_to_choose_start_date_on_waiver = false
        if $scope.membershipPlan.default_start_date_strategy is 'start_on_purchase_date'
          $scope.membershipPlan.default_upfront_date_strategy = 'charge_upfront_amount_on_start_date'

      $scope.onDefaultUpfrontStrategyChange = ->
        if $scope.isUpfrontAndRecurring()
          $scope.membershipPlan.default_repeat_date_strategy = 'start_repeat_payments_on_membership_start_date'

      $scope.onlyOnMembershipStartDateUpfronttDateStrategyAvailable = ->
        plan = $scope.membershipPlan
        ($scope.isUpfront() || $scope.isUpfrontAndRecurring()) &&
          plan.default_start_date_strategy is 'start_on_purchase_date'

      $scope.onlyOnMembershipStartDateRepeatDateStrategyAvailable = ->
        plan = $scope.membershipPlan
        $scope.isUpfrontAndRecurring() &&
          plan.default_upfront_date_strategy in ['charge_upfront_amount_relative_to_start_date', 'charge_upfront_amount_asap']

      $scope.membershipStartDateRepeatDateStrategyUnavailable = ->
        plan = $scope.membershipPlan
        $scope.isUpfrontAndRecurring() &&
          plan.default_start_date_strategy is 'start_on_purchase_date'

      $scope.getUpfrontStrategy = ->
        switch $scope.membershipPlan.default_upfront_date_strategy
          when 'charge_upfront_amount_on_start_date'
            'when the membership starts'
          when 'charge_upfront_amount_relative_to_start_date'
            unit = $scope.membershipPlan.relative_upfront_date_unit
            num = $scope.membershipPlan.relative_upfront_date_number
            period = $filter('pluralize')(unit, num, true)
            "#{period} before the membership starts, or immediately if that date has passed"
          when 'charge_upfront_amount_asap'
            'as soon as possible'

      $scope.getFrequency = ->
        number = $scope.membershipPlan.charge_interval_number
        unit = $scope.membershipPlan.charge_interval_unit
        period = $filter('pluralize')(unit, number, number > 1)
        "every #{period}"

      $scope.getRepeatStrategy = ->
        plan = $scope.membershipPlan
        switch plan.default_repeat_date_strategy
          when 'start_repeat_payments_one_period_after_upfront'
            number = plan.charge_interval_number
            unit = plan.charge_interval_unit
            period = $filter('pluralize')(unit, number, true)
            "#{period} after initial payment"
          when 'start_repeat_payments_on_membership_start_date'
            "when the membership starts"
          when 'start_repeat_payments_relative_to_start_or_upfront'
            number = plan.relative_repeat_date_number
            unit = plan.relative_repeat_date_unit
            period = $filter('pluralize')(unit, number, true)
            date = if parseFloat(plan.cost_upfront) > 0
              "initial payment"
            else
              "membership starts"
            "#{period} after #{date}"

      $scope.getExpiry = ->
        return 'indefinitely' unless $scope.membershipPlan.term_number > 0
        number = $scope.membershipPlan.term_number
        unit = $scope.membershipPlan.term_unit
        period = $filter('pluralize')(unit, number, true)
        "for #{period}"

      paymentsRelatedKeys = [
        'cost_upfront'
        'cost_repeat'
        'charge_interval_unit'
        'charge_interval_number'
        'term_unit'
        'term_number'
        'default_start_date_strategy'
        'default_upfront_date_strategy'
        'default_repeat_date_strategy'
        'specified_start_date'
        'relative_start_date_number'
        'relative_start_date_unit'
        'relative_upfront_date_number'
        'relative_upfront_date_unit'
        'relative_repeat_date_number'
        'relative_repeat_date_unit'
        'start_on_next_upcoming_day'
        'start_on_next_upcoming_month'
      ]

      getTotalToCollect = _.memoize (plan) ->
        hasUpfrontPayment = parseFloat(plan.cost_upfront) > 0
        hasRepeatPayment = parseFloat(plan.cost_repeat) > 0

        if !hasUpfrontPayment && !hasRepeatPayment
          return 0

        if hasUpfrontPayment && !hasRepeatPayment
          return parseFloat(plan.cost_upfront)

        if hasRepeatPayment && !(plan.term_number > 0) || !(plan.charge_interval_number > 0)
          return 'N/A'

        url = "/gyms/#{$scope.gym.id}/membership_plans/estimate_total_to_collect"
        params =
          membership_plan: _.pick $scope.membershipPlan, paymentsRelatedKeys
        $http.post(url, params)
      , () ->
        plan = $scope.membershipPlan
        values = paymentsRelatedKeys.map (key) -> $scope.membershipPlan[key]
        values.join('_')

      watchMembershipTotal = _.debounce (plan, oldPlan) ->
        $timeout ->
          total = getTotalToCollect(plan)
          if total.then
            total.then (response) ->
              $scope.totalToCollect = $filter('currency')(response.data.value, $scope.currency_symbol)
          else
            if total > 0
              $scope.totalToCollect = $filter('currency')(total, $scope.currency_symbol)
            else
              $scope.totalToCollect = total
      , 1500

      $scope.$watch 'membershipPlan', watchMembershipTotal, true
