angular.module 'website', [
  'angular-flash.service',
  'angular-flash.flash-alert-directive'
  'ui.calendar',
  'ui.bootstrap',
  'ui.router',
  'gym.components',
  'filters',
  'shared',
  'signature',
  'ngTable',
  'restangular',
  'duScroll',
  'tandibar/ng-rollbar',
  'react'
]
.run (Restangular, restangularInterceptors, $rootScope, loadingAnimation) ->
  loadingAnimation.hide()

  $rootScope.$on '$stateChangeSuccess', ->
    loadingAnimation.hide()

  $rootScope.$on '$stateChangeError', (event, toState, toParams, fromState, fromParams, error) ->
    loadingAnimation.hide()

  $rootScope.$on '$statNotFound', ->
    loadingAnimation.hide()

  Restangular
    .addRequestInterceptor restangularInterceptors.RequestInterceptor
    .addResponseInterceptor restangularInterceptors.ResponseInterceptor

.config (
  $stateProvider,
  $locationProvider,
  $httpProvider
  RollbarProvider
) ->
  $locationProvider.html5Mode(true)
  RollbarProvider.init window._rollbarConfig
  meta = angular.element(document.querySelectorAll('meta[name=csrf-token]'))
  $httpProvider.defaults.headers.common['X-CSRF-Token'] = meta.attr('content')
  $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

  $stateProvider
    .state 'website',
      url: '/websites/:domain'
      template: '<ui-view autoscroll="false"></ui-view>'

    .state 'website.calendar',
      url: '/calendar?location_id&ex_location_id&event_id&location_id[]&ex_location_id[]&event_type_id&event_type_id[]&instructor_id&instructor_id[]'
      template: require('templates/website/calendar/calendar.html.slim')
      controller: 'website.calendarCtrl'
      reloadOnSearch: false

    .state 'website.calendarWeeklyAgenda',
      url: '/calendar/weekly_agenda?location_id&ex_location_id&location_id[]&ex_location_id[]&event_id&event_type_id&event_type_id[]&instructor_id&instructor_id[]'
      template: '<website-weekly-agenda></website-weekly-agenda>'
      reloadOnSearch: false

    .state 'website.calendarFreeTrials',
      url: '/calendar/free_trials?location_id&ex_location_id&location_id[]&ex_location_id[]&event_id&event_type_id&event_type_id[]&instructor_id&instructor_id[]'
      template: '<website-weekly-agenda is-free-trials="true"></website-weekly-agenda>'
      reloadOnSearch: false

    .state 'website.calendarIframe',
      url: '/calendar/iframe?location_id&ex_location_id&event_id&location_id[]&ex_location_id[]&event_type_id&event_type_id[]&instructor_id&instructor_id[]'
      template: require('templates/website/calendar/iframe.html.slim')
      controller: 'website.calendarCtrl'
      reloadOnSearch: false

    .state 'website.weeklyAgendaCalendarIframe',
      url: '/calendar/weekly_agenda_iframe?location_id&ex_location_id&location_id[]&ex_location_id[]&event_id&event_type_id&event_type_id[]&instructor_id&instructor_id[]'
      template: '<website-weekly-agenda hide-navigation="true"></website-weekly-agenda>'
      reloadOnSearch: false

    .state 'website.freeTrialsCalendarIframe',
      url: '/calendar/free_trials_iframe?location_id&ex_location_id&location_id[]&ex_location_id[]&event_id&event_type_id&event_type_id[]&instructor_id&instructor_id[]'
      template: '<website-weekly-agenda is-free-trials="true" hide-navigation="true"></website-weekly-agenda>'
      reloadOnSearch: false

    .state 'website.waiver',
      url: '/waivers/:waiverName/signed_waivers'
      template: '<ui-view>'
      abstract: true

    .state 'website.waiver.new',
      url: '/new?click_and_pay_plan'
      template: require('templates/website/waivers/new.html.slim')
      controller: 'website.newWaiverCtrl'
      resolve:
        waiverPath: ($stateParams, Restangular) ->
          Restangular.one('websites', $stateParams.domain)
            .one('waivers', $stateParams.waiverName)

        waiver: (waiverPath) ->
          waiverPath.one('signed_waivers', 'new').get()

    .state 'website.waiver.prefill',
      url: '/:prefill_code'
      template: require('templates/website/waivers/new.html.slim')
      controller: 'website.newWaiverCtrl'
      resolve:
        waiverPath: ($stateParams, Restangular) ->
          Restangular.one('websites', $stateParams.domain)
            .one('waivers', $stateParams.waiverName)

        waiver: ($stateParams, waiverPath) ->
          waiverPath.one('signed_waivers', $stateParams.prefill_code)
            .get().then (waiver) ->
              waiver
            , (response) ->
              loadError: response.data.error

    .state 'website.waiver.receipt',
      url: '/:signedWaiverId/receipt'
      template: require('templates/website/waivers/receipt.html.slim')
      controller: 'website.waiverReceiptCtrl'
      resolve:
        receipt: ($stateParams, Restangular) ->
          Restangular.one('websites', $stateParams.domain)
            .one('waivers', $stateParams.waiverName)
            .one('signed_waivers', $stateParams.signedWaiverId)
            .doGET('receipt').then (receipt) ->
              receipt
            , () -> {}

    .state 'website.waiverChangesReview',
      url: '/waivers/:waiverName/waiver_changes/:waiverChangesId'
      template: require('templates/website/waivers/waiver_changes.html.slim')
      controller: 'website.waiverChangesCtrl'
      resolve:
        waiver: ($stateParams, Restangular) ->
          Restangular.one('websites', $stateParams.domain)
            .one('waivers', $stateParams.waiverName)
            .one('waiver_changes', $stateParams.waiverChangesId).get()

    .state 'website.gallery',
      url: '/gallery_images'
      template: require('templates/website/gallery/index.html.slim')
      controller: 'website.galleryCtrl'

.provider 'loadingAnimation',
  class LoadingAnimation
    $get: ->
      new LoadingAnimation()
    animationDiv: ->
      $('.worx-loading-background')
    show: ->
      @animationDiv().removeClass('hidden')
    hide: ->
      @animationDiv().addClass('hidden')

.run ($rootScope, $log, Restangular, restangularInterceptors)->
  $rootScope.$on '$stateChangeStart', (event, toState, toParams) ->
    $rootScope.toParams = toParams

  $rootScope.$on '$stateChangeError', (event, toState, toParams, fromState, fromParams, error) ->
    $log.error error

  Restangular
    .addRequestInterceptor restangularInterceptors.RequestInterceptor
    .addResponseInterceptor restangularInterceptors.ResponseInterceptor
