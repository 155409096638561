angular
  .module 'gym.components'
  .directive 'membershipList', ->
    restrict: 'E'
    template: require('templates/components/contacts/forms/membership_list.html.slim')
    scope:
      contact: '='
      memberships: '='
      permissions: '='
      archive: '&'
      addMembership: '&'
      reloadContact: '&'
      onMembershipRemoved: '&'
      setTab: '&'
    controller: (
      $scope,
      $rootScope,
      $stateParams,
      $modal,
      $timeout,
      $window,
      $http,
      loadingAnimation,
      flash,
      ReactModal,
      EventService
    ) ->
      gym = $rootScope.gym
      $scope.isCollapsed = true

      $scope.hasMembershipStarted = (membership) ->
        moment(membership.start_date).isSameOrBefore(moment().tz(gym.tz), 'day')

      $scope.inactiveMemberships = ->
        _.filter $scope.memberships, (membership) -> !membership.active

      $scope.getMemberships = ->
        if $scope.isCollapsed
          _.filter $scope.memberships, (membership) -> membership.active
        else
          $scope.memberships

      $scope.toggleList = ->
        $scope.isCollapsed = !$scope.isCollapsed

      $scope.displayMembershipReplacementModal = (membership) ->
        modalInstance = $modal.open
          template: require('templates/components/membership/membership_replacement_modal.html.slim')
          resolve:
            membership: ->
              membership
            contact: ->
              $scope.contact
          controller: 'MembershipReplacementModalCtrl'

      removeMembership = (membership) ->
        loadingAnimation.show()
        type = $stateParams.contactType
        url = "/gyms/#{gym.id}/#{type}/#{$scope.contact.id}/memberships/#{membership.id}"
        $http.delete(url)

      onMembershipRemoved = ->
        $scope.reloadContact().then ->
          flash.success = "Membership successfully deleted"
          $scope.$emit 'invoices:list:reload'
          loadingAnimation.hide()

      archiveMembership = (membership, displayBrivoDeleteWarning) ->
        cancellation_fee_status =
          membership.membership_cancellation?.cancellation_fee_status
        defaultMessage = "Are you sure you want to remove this membership?"
        message = switch
          when cancellation_fee_status is 'upcoming'
            "#{defaultMessage}\nPlease note: this will remove the cancellation fee for this membership."
          when cancellation_fee_status in ['paid', 'pending']
            "#{defaultMessage}\nPlease note: The cancellation fee for this membership has already been processed."
          else
            defaultMessage
        if displayBrivoDeleteWarning
          message = "#{message}\n\nPlease note: deleting this membership will
          cancel this member, and may remove their User record on Brivo. If you
          wish to avoid this, please add a replacement membership before
          deleting this one."

        if $window.confirm message
          removeMembership(membership).then ->
            onMembershipRemoved()

      $scope.onArchive = (membership) ->
        activeMembershipsCount = _.filter($scope.contact.memberships, active: true).length
        displayBrivoDeleteWarning =
          $scope.contact.display_brivo_membership_delete_warning && activeMembershipsCount is 1

        if membership.active && membership.allow_recurring_bookings && !membership.membership_cancellation
          loadingAnimation.show()
          EventService.getRecurringBookings(membership_id: membership.id).then (recurringBookings) ->
            loadingAnimation.hide()
            if recurringBookings.length
              modalInstance = ReactModal.open
                component: 'DeactivateRecurringBookingsModal'
                props:
                  contact: $scope.contact
                  membership: membership
                  displayBrivoDeleteWarning: displayBrivoDeleteWarning
                  recurringBookings: recurringBookings
              modalInstance.then (params) ->
                onMembershipRemoved()
            else
              archiveMembership(membership, displayBrivoDeleteWarning)
          , () ->
            flash.error = 'Request could not be completed. Please try again'
            loadingAnimation.hide()
        else

          if membership.signed_waiver_id
            modalInstance = $modal.open
              template: require('templates/gyms/contacts/delete_linked_memberships_modal.html.slim')
              resolve:
                contact: -> $scope.contact
                membership: -> membership
                displayBrivoDeleteWarning: ->
                  displayBrivoDeleteWarning
              controller: 'DeleteLinkedMembershipsCtrl'

            modalInstance.result.then (params) ->
              onMembershipRemoved()
          else
            archiveMembership(membership, displayBrivoDeleteWarning)

      $scope.isMakeUpClassAvailable = ->
        $scope.contact.type isnt 'non_attending_contact' && $scope.permissions.manage_makeup_classes

      $scope.displayMakeUpClass = ->
        $scope.setTab({tab: 'attendance', params: {attendanceTab: 'make_up_classes'}})
        $timeout ->
          $scope.$emit 'contact:make_up_classes:reload'
        , 100

      $scope.addMakeUpClass = (membership) ->
        modalInstance = $modal.open
          template: require('templates/components/membership/membership_make_up_modal.html.slim')
          resolve:
            membership: -> membership
            contact: -> $scope.contact
            makeUpClass: -> {}
          controller: 'MembershipMakeUpClassModalCtrl'

        modalInstance.result.then ->
          $scope.$emit 'contact:make_up_classes:reload'
          $scope.reloadContact()
