import random from 'helpers/random'
import {SMS_OPT_OUT_INSTRUCTIONS_POLICY_OPTIONS} from 'react/types/sms_settings'
import {SMS_OPT_OUT_POLICY_HINT_TEXT} from 'react/types/constants'
import featureNames from 'constants/feature_names'

angular
  .module 'shared'
  .directive 'oldTemplateEditor', (
    $rootScope,
    $filter,
    $modal,
    flash,
    SmsValidationService,
    PushNotificationValidationService,
    SMSesSentThisMonthService,
    FeatureAvailabilityService,
    MERGE_TAG_LIST,
    PAYMENT_MERGE_TAGS,
    CREDIT_VOUCHER_MERGE_TAGS,
    BOOKING_MERGE_TAGS,
    MEMBERSHIP_MERGE_TAGS,
    GRADING_EVENT_MERGE_TAGS,
    MEMBER_PORTAL_MERGE_TAG_LIST,
    GRADING_MERGE_TAGS,
    STYLE_MERGE_TAGS,
    SUSPENSION_MERGE_TAGS,
    RECURRING_BOOKING_MERGE_TAGS,
    LATE_CANCELLED_BOOKING_MERGE_TAGS,
    BOOKING_CANCELLATION_MERGE_TAGS,
    WAITLIST_ATTENDEE_MERGE_TAGS,
    PERFORMED_WORKOUT_MERGE_TAGS,
    MOBILE_APP_EMAIL_INVITE_MERGE_TAG_LIST,
    MOBILE_APP_SMS_INVITE_MERGE_TAG_LIST
  ) ->
    restrict: 'E'
    template: require('templates/shared/old_template_editor.html.slim')
    scope:
      template: '='
      hideName: '='
      displayPurpose: '='
      displaySequenceMergeTags: '='
      displayRollCallMergeTags: '='
      displayBookingMergeTags: '='
      displayMembershipMergeTags: '='
      displayPaymentMergeTags: '='
      displayGradingEventMergeTags: '='
      error: '='
      toolbarStatic: '='
      disabled: '='
    link:
      pre: (scope) ->
        gym = $rootScope.gym
        scope.showSmsOptOutPolicy = gym.presented_gym_sms_detail?.opt_out_policy_enabled
        scope.template.sms_opt_out_instructions_policy = scope.template.sms_opt_out_instructions_policy || gym.presented_gym_sms_detail.sms_opt_out_instructions_policy
        scope.smsOptOutInstructionsOptions = SMS_OPT_OUT_INSTRUCTIONS_POLICY_OPTIONS;
        scope.pushNotificationRoutes = scope.pushNotificationRoutes

        scope.smsHintText = SMS_OPT_OUT_POLICY_HINT_TEXT
        scope.id = random()

        scope.merge_tags = switch
          when scope.template.role is 'mobile_app_invite_prompt' && scope.template.channel is 'email'
            [ MERGE_TAG_LIST.concat(MOBILE_APP_EMAIL_INVITE_MERGE_TAG_LIST) ]
          when scope.template.role is 'mobile_app_invite_prompt' && scope.template.channel is 'sms'
            [ MERGE_TAG_LIST.concat(MOBILE_APP_SMS_INVITE_MERGE_TAG_LIST) ]
          when scope.template.role is 'mobile_app_invite_confirmation'
            [ MERGE_TAG_LIST.concat('mobile-app-username') ]
          when scope.template.role is 'mobile_app_password_reset_prompt'
            [ MERGE_TAG_LIST.concat('mobile-app-password-reset-link') ]
          else
            [ MERGE_TAG_LIST ]

        if gym.member_portal_enabled
          scope.merge_tags = [scope.merge_tags[0].concat(MEMBER_PORTAL_MERGE_TAG_LIST)]

        if gym.mobile_app_enabled && scope.template.role isnt 'mobile_app_invite_prompt'
          scope.merge_tags = [scope.merge_tags[0].concat(['mobile-app-ios-app-store-link', 'mobile-app-google-app-store-link'])]

        scope.payment_merge_tags = if FeatureAvailabilityService.isAvailable('credit_vouchers')
          [ PAYMENT_MERGE_TAGS.concat(CREDIT_VOUCHER_MERGE_TAGS) ]
        else
          [ PAYMENT_MERGE_TAGS ]
        scope.booking_merge_tags = [ BOOKING_MERGE_TAGS ]
        scope.membership_merge_tags = [ MEMBERSHIP_MERGE_TAGS ]
        scope.grading_event_merge_tags = [ GRADING_EVENT_MERGE_TAGS ]
        scope.grading_merge_tags = [ GRADING_MERGE_TAGS ]
        scope.style_merge_tags = [ STYLE_MERGE_TAGS ]
        scope.suspension_merge_tags = [ SUSPENSION_MERGE_TAGS ]
        scope.recurring_booking_merge_tags = [ RECURRING_BOOKING_MERGE_TAGS ]
        scope.late_cancelled_booking_merge_tags = [ LATE_CANCELLED_BOOKING_MERGE_TAGS ]
        scope.booking_cancellation_merge_tags = [ BOOKING_CANCELLATION_MERGE_TAGS ]
        scope.waitlist_attendee_merge_tags = [ WAITLIST_ATTENDEE_MERGE_TAGS ]
        scope.performed_workout_merge_tags = [ PERFORMED_WORKOUT_MERGE_TAGS ]

        SMSesSentThisMonthService.load()
        scope.getSmsesSentThisMonth = SMSesSentThisMonthService.getCount

        scope.getRemainingCharacters = ->
          if scope.template.channel is 'sms'
            return SmsValidationService.getRemainingCharacters(scope.template.content)
          if scope.template.channel is 'push_notification'
            return PushNotificationValidationService.getRemainingCharacters(scope.template.content)

        scope.displayRemainingCharacters = ->
          if scope.template.channel is 'sms'
            return SmsValidationService.displayRemainingCharacters(scope.template.content)
          if scope.template.channel is 'push_notification'
            PushNotificationValidationService.displayRemainingCharacters(scope.template.content)

        scope.displayRemainingCharactersDetails = ->
          $modal.open
            template: require('templates/gyms/bulk_messages/remaining_characters_details.html.slim')
            controller: 'RemainingCharactersDetailsModal'

        unless scope.template
          scope.template = channel: 'email'

        scope.purposeOptions = [
          ['general_purpose_template', 'General Use']
        ]

        if scope.displayPurpose
          if gym.is_martial_arts
            scope.purposeOptions.push ['grading_event_template', 'Grading Events']

          if gym.mobile_app_enabled
            scope.purposeOptions.push ['mobile_app_template', 'Mobile App']

        scope.template.purpose ||= scope.purposeOptions[0][0]

        scope.$watch 'template.channel', (channel) ->
          if channel in ['sms', 'push_notification'] and scope.template.content?
            scope.template.content = $filter('sanitizeEditorField')(scope.template.content)

    controller: ($scope, $rootScope, CommunicationTemplateCategories, PushNotificationOptionsService, flash) ->
      gym = $rootScope.gym

      $scope.featureNames = featureNames
      $scope.templateEditorData =
        selectedSequenceMergeTags: ''

      $scope.getMergeTagMessage = ->
        if $scope.templateEditorData?.selectedSequenceMergeTags in ['booking', 'membership']
          "These merge tags can be used in messages sent from #{$scope.featureNames.SEQUENCE_PLURAL}, or the event calendar."
        else
          "These merge tags can only be used in messages sent from #{$scope.featureNames.SEQUENCE_PLURAL}"

      $scope.mobileAppEnabled = ->
        gym?.mobile_app_enabled && $rootScope.mobileAppMeta?.push_notifications_feature_enabled

      $scope.recurringBookingsEnabled = gym?.gym_config.recurring_bookings_feature_enabled

      $scope.sequenceMergeTagOptions =  _.compact([
        { value: 'payment', label: "For Payment #{featureNames.SEQUENCE_PLURAL_LOWERCASE}" },
        { value: 'booking', label: "For Booking #{featureNames.SEQUENCE_PLURAL_LOWERCASE}" },
        { value: 'membership', label: "For Membership #{featureNames.SEQUENCE_PLURAL_LOWERCASE}" },
        gym?.is_martial_arts && { value: 'grading', label: "For Grading #{featureNames.SEQUENCE_PLURAL_LOWERCASE}" },
        gym?.is_martial_arts && { value: 'style', label: "For Style #{featureNames.SEQUENCE_PLURAL_LOWERCASE}" },
        { value: 'suspension', label: "For Suspension #{featureNames.SEQUENCE_PLURAL_LOWERCASE}" },
        $scope.recurringBookingsEnabled && {
          value: 'recurring_booking',
          label: "For Recurring Booking #{featureNames.SEQUENCE_PLURAL_LOWERCASE}",
        },
        {
          value: 'booking_cancellation',
          label: "For Booking cancelled #{featureNames.SEQUENCE_PLURAL_LOWERCASE}",
        },
        {
          value: 'late_cancelled_booking',
          label: "For Booking late-cancelled #{featureNames.SEQUENCE_PLURAL_LOWERCASE}",
        },
        {
          value: 'waitlist_attendee',
          label: "For Contact Added to Event Waitlist #{featureNames.SEQUENCE_PLURAL_LOWERCASE}",
        },
        {
          value: 'performed_workout',
          label: "For Workout Performed #{featureNames.SEQUENCE_PLURAL_LOWERCASE}",
        }
      ])
      $scope.pushNotificationRoutes = [{label: "No preference (open app to last viewed screen)", value: ''}]
      $scope.template.push_notification_route_name = $scope.template.push_notification_route_name || $scope.pushNotificationRoutes[0].value

      $scope.loadPushNotificationRoutes = ->
        PushNotificationOptionsService.getOptions(gym.id, {})
          .then (data) ->
            if data.length
              $scope.pushNotificationRoutes = $scope.pushNotificationRoutes.concat(data)
            else
              $scope.pushNotificationRoutes = []
          .catch (error) ->
            flash.error error

      $scope.loadPushNotificationRoutes() if $scope.displayPurpose
                
      loadCategories = ->
        CommunicationTemplateCategories.getList(count: 100).then (categories) ->
          $scope.templateCategories = categories

      loadCategories() if $scope.displayPurpose

      $scope.manageCategories = ->
        categoriesModal = $modal.open
          template: require('templates/shared/manageTemplatesCategoryModal.html.slim')
          controller: 'ManageTemplatesCategoryModal'
          resolve:
            categories: -> $scope.templateCategories

        categoriesModal.result.then ->
          loadCategories()
        , ->
          loadCategories()
